import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useLanguage } from "../context/LanguageContext";
import { translate } from "../utils/translation";

function IndexPage() {
  const { language } = useLanguage() || { language: "en" }; // Fallback to "en" if language is undefined

  return (
    <Layout>
      <SEO
        keywords={[
          `web development`,
          `full stack developer`,
          `Barcelona`,
          `Giuseppe Gangi`,
          `web applications`,
          `software solutions`,
          `engineering`,
        ]}
        title={translate("index.title", language)}
      />

      <div className="text-center mt-2 mb-2">
        <a
          href="https://buymeacoffee.com/giuseppe.gangi"
          className="inline-block w-full md:w-auto text-center px-6 py-3 text-white bg-yellow-600 rounded-full hover:bg-yellow-700 text-lg font-bold"
        >
          {translate("index.invite_coffee", language)}
        </a>
      </div>
      <section className="presentation flex flex-wrap md:flex-nowrap items-center justify-center md:justify-start -mx-1 overflow-hidden space-y-6 md:space-y-6">
        <div className="w-full md:w-1/3 order-1 px-1 overflow-hidden mb-4 md:mb-0 flex justify-center">
          <img
            src="https://github.com/ggangix.png"
            className="w-48 h-48 md:w-52 md:h-52 rounded-full object-cover shadow-lg border-4 border-gray-200"
            alt="Giuseppe Gangi"
          />
        </div>
        <div className="w-full md:w-2/3 order-2 px-4 md:px-0">
          <p className="text-lg md:text-xl mb-4 text-gray-700 leading-relaxed text-justify">
            {translate("index.description1", language)}
          </p>
          <p className="text-lg md:text-xl mb-4 text-gray-700 leading-relaxed text-justify">
            {translate("index.description2", language)}
          </p>
          <p className="text-lg md:text-xl mb-6 text-gray-700 leading-relaxed text-justify">
            {translate("index.description4", language)}
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
